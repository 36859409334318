import { WidgetTokenInterface } from './widget-config.interface';

const tokenConfigs: WidgetTokenInterface[] = [
  {
    hosts: ['landgoedborkerheide.nl', 'boekenzuil.landgoedborkerheide.nl', 'test.boekenzuil.landgoedborkerheide.nl', 'landgoedborkerheide.local'],
    options: {
      usePin: true,
      theme: 'landgoedborkerheide',
      token: 'Borkerheide:a7be8e1fe282a37cd666e0632b17d933fa13f21addf4798fc0455bc166e2488c',
      colorLoader: '#9d2931',
      colorZoomBg: '#562b4c',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyCyN5t2dusGkKJHgMPoy8MNGVwvA8ZgPyQ',
      favicon: '/favicon-landgoedborkerheide.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Landgoed Borkerheide', website_url: 'www.landgoedborkerheide.nl', phone_number: '' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [3562],
      salesChannel: 10114,
      petsConfig: { articles: [81241, 81621], max: 2 },
      alternativeResults: true,
      useMarkerClusterer: false,
    },
  },
  {
    hosts: ['vreehorst.nl', 'vreehorst.localhost', 'test.boekenzuil-vreehorst.3wstaging.nl', 'tommywizard.3wstaging.nl'],
    options: {
      usePin: false,
      theme: 'vreehorst',
      token: 'vreehorst:ce0d320a502ecee73ce06e640cad426ce1b955d3c5202ebc3f9d03df1ee7379b',
      colorLoader: '#a2c312',
      colorZoomBg: '#0083A1',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDLXXvOq9BJxt9btrZOtgiE9uV6TUUxDIg',
      favicon: '/favicon-vreehorst.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Vreehorst', website_url: 'www.vreehorst.nl', phone_number: '0543 514 805' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [2987, 2091],
      salesChannel: 9574,
      petsConfig: { articles: [48625], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {
        14337: 'price-no-reductionpass',
        14333: 'price-no-reductionpass',
        14335: 'price-no-reductionpass',
        19849: 'price-no-reductionpass',
      },
    },
  },
  {
    hosts: [
      'test.11950.boekenzuil-vreehorst.3wstaging.nl',
      '11950.boekenzuil-vreehorst.3wstaging.nl',
      '11950.boekenzuil.vreehorst.nl',
      'vreehorst-11950.localhost',
    ],
    options: {
      usePin: true,
      theme: 'vreehorstcp',
      token: 'vreehorst:ce0d320a502ecee73ce06e640cad426ce1b955d3c5202ebc3f9d03df1ee7379b',
      colorLoader: '#a2c312',
      colorZoomBg: '#0083A1',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDLXXvOq9BJxt9btrZOtgiE9uV6TUUxDIg',
      favicon: '/favicon-vreehorst.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Vreehorst', website_url: 'www.vreehorst.nl', phone_number: '0543 514 805' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [2987, 2091],
      salesChannel: 11950,
      petsConfig: { articles: [48625], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {
        14337: 'price-no-reductionpass',
        14333: 'price-no-reductionpass',
        14335: 'price-no-reductionpass',
        19849: 'price-no-reductionpass',
      },
    },
  },
  {
    hosts: ['reestland.3wstaging.nl', 'reestland.local', 'reestlandtest.3wstaging.nl'],
    options: {
      usePin: true,
      theme: 'reestland',
      token: 'reestland:556ea1b2f7420f2cd4e6d1d548f7389cdaf91535020c5917e16d2b3bf6b98844',
      colorLoader: '#a2c312',
      colorZoomBg: '#0083A1',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDknFIA662kSGtljapgIasq8ZXgXT3NidU',
      favicon: '/favicon-reestland.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Reestland', website_url: 'www.reestland.nl', phone_number: '' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [2467, 2473, 6420, 6246, 6267],
      salesChannel: 13827,
      petsConfig: { articles: [62037, 62007], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['boekenzuil.campingkostverloren.nl', 'campingkostverloren.3wstaging.nl', 'kostverloren.local', 'node.php73.cms'],
    options: {
      usePin: true,
      theme: 'kostverloren',
      token: 'kostverloren:f06ca10f4977ea7a4857646c826cc9d4b5fa691e2c57766a98ed93dc6001ecde',
      colorLoader: '#f2fabb',
      colorZoomBg: '#7da74f',
      colorZoomFg: '#04380a',
      mapsKey: 'AIzaSyBNb7oHj8ZaBNlmFd42Ul9F0B6lfEz4sIA',
      favicon: '/favicon-kostverloren.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Kostverloren', website_url: 'www.campingkostverloren.nl', phone_number: '' },
      travelGroupLimits: { '6736': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [5194],
      salesChannel: 13986,
      // salesChannel: 9040,
      petsConfig: { articles: [87772], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['boekenzuil.lutjekossink.nl', 'lutjekossink.3wstaging.nl', 'lutjekossink.local'],
    options: {
      usePin: false,
      theme: 'lutjekossink',
      token: 'lutjekossink:867067959260d3f3a0d605f9fcc328cc1d891fb6c13643edbf29163ca5e0ba70',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyDxPZKrJrkcrN7Y2sGEStr6Wvj8BFNMlgg',
      favicon: '/favicon-lutjekossink.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping LutjeKossink', website_url: 'www.lutjekossink.nl', phone_number: '' },
      travelGroupLimits: { '10068': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [6614, 6611],
      salesChannel: 14181,
      petsConfig: { articles: [156125, 155157], max: 2 },
      extrasConfig: { disabled: [3194, 3197] },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
      disableActioncode: true
    },
  },
  {
    hosts: ['boekenzuil.camperparkzeeland.nl', 'camperparkzeeland.3wstaging.nl', 'camperparkzeeland.local', 'boekenzuil.camperparkzeeland.3wstaging.nl'],
    options: {
      usePin: true,
      theme: 'camperparkzeeland',
      token: 'CamperparkZeeland:46957db596ec41cc39bbfe0083740a488c59970b13e4b39e7937edb518696e3b',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyCl9BAnpTgvxv8gl3_ssLcCGFin2g8i--Q',
      favicon: '/favicon-camperparkzeeland.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camperparkzeeland', website_url: 'www.camperparkzeeland.nl', phone_number: '31 (0)118 700 225' },
      travelGroupLimits: { '10403': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [7105],
      salesChannel: 14585,
      petsConfig: { articles: [163988], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
      minimalMaps: true
    },
  },
  {
    hosts: ['boekenzuil.zeehoeve.nl', 'zeehoeve.3wstaging.nl', 'zeehoeve.local'],
    options: {
      usePin: true,
      theme: 'zeehoeve',
      token: 'Zeehoeve:41214f55c1e8d6170239eb93d7cc105abbee1dc0fbe8f7a258962da285dfe9cd',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyCycOYdcjAtlBgPEvT3YXS8gUw9DDA0pVc',
      favicon: '/favicon-zeehoeve.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Recreatie camping zeehoeve', website_url: 'www.zeehoeve.nl', phone_number: '+31 (0) 517 413465' },
      travelGroupLimits: { '9738': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [
        6647, // camperplaats
        6348, // B&B
        6336, // Verhuur
        6333, // Kamperen
      ],
      salesChannel: 14055,
      petsConfig: { articles: [], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['boekenzuil.campingmuralt.nl', 'muralt.local', 'muraltzuil.3wstaging.nl'],
    options: {
      usePin: true,
      theme: 'muralt',
      token: 'muralt:a335677eb1814a2aa07fc0df670db4bc04bb43cc72413d461f6fd362289d75e4',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyByT8fatsGA_ki2yVngaz1hsybi3YGgFEA',
      favicon: '/favicon-muralt.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping muralt', website_url: 'www.campingmuralt.nl', phone_number: '0031 (0)111-671575' },
      travelGroupLimits: { '1457': { min: 0, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [
        777, // Kamperen
        779, // Huren
        1995, // Seizoensplaats
        1997, // Chalet jaarplaats
        1999, // Stalling
        2959, // Renewi planning
        2961, // Renewi leidingschema
        2423, // Schouw groepsruimte verhuur
      ],

      salesChannel: 18532,
      petsConfig: { articles: [], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['boekenzuil.biesthoutakker.nl', 'biesthoutakker.local', 'biesthoutakker.3wstaging.nl', 'boekenzuil.camperplaatsbiesthoutakker.nl'],
    options: {
      usePin: true,
      theme: 'biesthoutakker',
      token: 'BiestHoutakker:e8202f39fad74acd9b6d38a6038da9e8682e857bbdb977cf7b913963bfa2b393',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyByT8fatsGA_ki2yVngaz1hsybi3YGgFEA',
      favicon: '/favicon-biesthoutakker.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camperplaats Biest-Houtakker', website_url: 'https://www.camperplaatsbiesthoutakker.nl', phone_number: '0031 (0)6-52583364' },
      travelGroupLimits: { '11053': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [8687],
      salesChannel: 15544,
      petsConfig: { articles: [], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['newcampingideal.local', 'newideal.local', 'newcampingideal.3wstaging.nl', 'boekenzuil.newcampingideal.be'],
    options: {
      usePin: true,
      theme: 'newideal',
      token: 'NewIdeal:ae6ce4a7288056e4f2dfd479b43b97726ada94d848f72352cd963082fb0cefae',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyBvZLzirQeX6Gi3Zs2HRMF3DxDHbhJGXow',
      favicon: '/favicon-newideal.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'New Camping Ideal', website_url: 'https://www.newcampingideal.be/', phone_number: '050 41 16 24' },
      travelGroupLimits: { '12701': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [8774],
      salesChannel: 18293,
      petsConfig: { articles: [196754], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['boekenzuil.maarnseberg.nl', 'www.boekenzuil.maarnseberg.nl', 'test.localhost', 'localhost'],
    options: {
      usePin: true,
      theme: 'maarnseberg',
      token: 'Maarnseberg_Leo:15657aabc43592ac3366cbc820f0e0c14783878fc2eb00397296d5ba2896de9b',
      colorLoader: '#108121',
      colorZoomBg: '#084411',
      colorZoomFg: '#108121',
      mapsKey: 'AIzaSyAy2FHbxzOwJypHmHtiim_Pscg2ebxXy7s',
      favicon: '/favicon-maarnseberg.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Maarnseberg', website_url: 'https://www.maarnseberg.nl/', phone_number: '0343-44 12 84' },
      travelGroupLimits: { '1551': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [585,587,9002],
      salesChannel: 18035,
      petsConfig: { articles: [], max: 1 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
  {
    hosts: ['boekenzuil.gaaspercamping.nl', 'www.boekenzuil.gaaspercamping.nl', 'boekenzuil-gaas.3wstaging.nl', 'boekenzuil-gaas-02.3wstaging.nl', 'wizard.localhost',  ],
    options: {
      usePin: true,
      theme: 'CamperparkAmsterdam',
      token: 'CamperparkAmsterdam:cfef26c8661f948f2c2a72ffa8e0eb07cf77bddd884b175a2c6d77fad8a5be64',
      colorLoader: '#97c220',
      colorZoomBg: '#1e5b22',
      colorZoomFg: '#97c220',
      mapsKey: 'AIzaSyBn6Ael7HqpNUWpfziMzibroiUA315gvw8',
      favicon: '/favicon-camperparkamsterdam.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camperpark Amsterdam', website_url: 'https://camperparkamsterdam.nl/', phone_number: '0031 – (0)20 – 696 73 26' },
      travelGroupLimits: { 14262: { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [],
      salesChannel: 20884,
      petsConfig: { articles: [221277], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
      useTimeTable: true,
      arrivalPrinter: 'BC3',
      disableActioncode: true
    },
  },
  {
    hosts: ['boekenzuil-gaas-01.3wstaging.nl'],
    options: {
      usePin: false,
      theme: 'CamperparkAmsterdam2',
      token: 'CamperparkAmsterdam:cfef26c8661f948f2c2a72ffa8e0eb07cf77bddd884b175a2c6d77fad8a5be64',
      colorLoader: '#97c220',
      colorZoomBg: '#1e5b22',
      colorZoomFg: '#97c220',
      mapsKey: 'AIzaSyBn6Ael7HqpNUWpfziMzibroiUA315gvw8',
      favicon: '/favicon-camperparkamsterdam.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camperpark Amsterdam', website_url: 'https://camperparkamsterdam.nl/', phone_number: '0031 – (0)20 – 696 73 26' },
      travelGroupLimits: { 14262: { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [],
      salesChannel: 21001,
      petsConfig: { articles: [247750], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      useReductionPass: false,
      accommodationLabels: {},
      useTimeTable: false,
      disableMaps: true,
      disableActioncode: true,
    },
  },
  {
    hosts: ['boekenzuil.viermannekesbrug.nl' ],
    options: {
      usePin: true,
      theme: 'Viermannekesbrug',
      token: 'Viermannekesbrug:3673b82b483a327f94177bd439d428e955f80250c1a4120e9d9b9aab2b996fe2',
      colorLoader: '#72b01d',
      colorZoomBg: '#3a5a40',
      colorZoomFg: '#72b01d',
      mapsKey: 'AIzaSyD0Ce40Fxm04ppB99g2_XJ9ICbp5EEFb8Y',
      favicon: '/favicon-viermannekesbrug.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Viermannekesbrug', website_url: 'https://www.viermannekesbrug.nl/', phone_number: '06 22 90 64 12' },
      travelGroupLimits: { 11095: { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [],
      salesChannel: 22645,
      petsConfig: { articles: [235318], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
    },
  },
];

export default tokenConfigs;

