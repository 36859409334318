
import Vue from 'vue';
import Button from '@/components/Button.vue';
import { StepInterface, ButtonInterface } from '@/model/';
import { wizardProvider } from '@/wizard/';
import { mapGetters } from 'vuex';
import { LayoutInterface } from '../model/layout.interface';
import { TommyClient } from '../wizard/Tommy/TommyClient';

export default Vue.extend({
  components: {
    Button,
  },
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    stepIndex: {
      type: Number,
      default: () => false as any,
    },
    stepInput: {
      type: Object,
      default: () => false as any,
    },
  },
  data() {
    return {
      step: {} as StepInterface,
      wizardProvider: wizardProvider,
      signupReservationNumber: '',
      signupReservationNumberError: null,
      signupReservationNumberSuccess: false,
    };
  },
  async mounted() {
    if (0 === this.stepIndex) {
      this.step = this.stepInput;
      this.step = await wizardProvider.configureStep(this.stepInput, this.choices);
      this.$store.commit('SET_STEP', this.step);
    } else {
      this.step = await wizardProvider.configureStep(this.stepInput, this.choices);
      this.$store.commit('SET_STEP', this.step);

      if (this.step && 'undefined' !== typeof this.step.skip) {
        this.$store.commit('ADD_SKIPPED_STEP', this.stepNumber);

        if (typeof {} === typeof this.step.skip) {
          this.chooseSkip({ button: { ...this.step.skip } });
        } else {
          this.chooseSkip({ button: { code: this.step.skip, label: this.step.skip } });
        }
      }
    }
  },
  methods: {
    async signupReservationNumberClick() {
      this.$store.commit('SET_LOADING', true);
      const client = this.$app.helper.getClient();
      const resp = await client.postSignupReservation(this.signupReservationNumber, this.meta.arrivalPrinter);

      if (resp.success !== true) {
        this.signupReservationNumberError = resp.msg;
        this.signupReservationNumberSuccess = false;
      } else {
        this.signupReservationNumberError = null;
        this.signupReservationNumberSuccess = true;
      }
      this.$store.commit('SET_LOADING', false);
    },
    buttonClass(button: ButtonInterface): string[] {
      const classes: any = [];
      const options: any = button.options || {};
      (options.classes || []).forEach((cls: string) => {
        classes.push(cls);
      });

      return classes;
    },
    choose(button: ButtonInterface) {
      this.$emit('choose', {
        button: button,
        step: this.step,
      });
    },
    chooseSkip(skipData: any) {
      this.$emit('choose', skipData);
    },
    getColumnClasses(column: any, base: any) {
      let classes = !base || !base.length ? [] : base;

      if (column.classes) {
        column.classes.forEach((item: string) => {
          classes.push(item);
        });
      }

      return classes;
    },
  },
  computed: {
    options(): any {
      if (this.step && this.step.options) {
        return this.step.options;
      }

      return false;
    },
    widget(): any {
      const options: any = this.options;
      if (options) {
        return options.widget || false;
      }

      return false;
    },
    layout(): LayoutInterface {
      let layout: any;
      if (this.step && this.step.layout) {
        layout = this.step.layout;
      } else {
        layout = {
          rows: [
            {
              columns: [
                {
                  type: 'title',
                  classes: ['col-12'],
                },
              ],
            },
            {
              columns: [
                {
                  type: 'buttons',
                  classes: ['col-12'],
                },
                {
                  type: 'widget',
                  classes: ['col-12'],
                },
              ],
            },
          ],
        };
      }

      return layout;
    },
    ...mapGetters(['stepNumber', 'choices', 'meta']),
  },
});
