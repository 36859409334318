// custom step with selection of widgets.
import BaseStep from '@/components/BaseStep.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import FormWidget from '@/components/Widget/FormWidget.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons/faAsterisk';
import { faDog } from '@fortawesome/pro-solid-svg-icons/faDog';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'core-js';
import Vue from 'vue';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { store } from '../../plugin/';
import '../../registerServiceWorker';
import { appService } from '../../service/AppService';
import App from './App.vue';
import AppStep from './AppStep.vue';
import tokenConfigs from './Setup/tokens';
import { WidgetTokenInterface } from './Setup/widget-config.interface';
import { tommyStoreModule } from './tommy-store';

store.registerModule('tommy', tommyStoreModule);

Vue.component('Popper', Popper);
Vue.component('FormWidget', FormWidget);
Vue.component('BaseStep', BaseStep);
Vue.component('AppStep', AppStep);
Vue.component('LoadingIndicator', LoadingIndicator);
Vue.component('VueLoader', PulseLoader);

library.add(faPlus as any);
library.add(faMinus as any);
library.add(faArrowLeft as any);
library.add(faArrowRight as any);
library.add(faTimes as any);
library.add(faTimesCircle as any);
library.add(faDog as any);
library.add(faAsterisk as any);
library.add(faInfoCircle as any);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

const countries: any = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));

let element: any = document.querySelectorAll('.wizard-app');

if (0 === element.length) {
  throw 'Failed to find element.';
}
element = element[0];

const requestHost: any = document.location.hostname;

let tokenConfig: WidgetTokenInterface | null = null;
tokenConfigs.forEach((testTokenConfig: any) => {
  for (const host of testTokenConfig.hosts) {
    if ((requestHost.indexOf('.') == -1 && -1 !== requestHost.indexOf(host)) || requestHost === host) {
      tokenConfig = testTokenConfig;
    }
  }
});

if (!tokenConfig) {
  throw 'Could not select token';
}

const usePin: boolean = tokenConfig.options.usePin || false;
const theme: any = tokenConfig.options.theme || null;
const token: any = tokenConfig.options.token || null;
const colorLoader: string | boolean = tokenConfig.options.colorLoader || undefined;
const colorZoomBg: string | boolean = tokenConfig.options.colorZoomBg || undefined;
const colorZoomFg: string | boolean = tokenConfig.options.colorZoomFg || undefined;
const mapsKey: any = tokenConfig.options.mapsKey || null;
const accommodationGroups: any = tokenConfig.options.accommodationGroups || undefined;
const entrypoints: any = tokenConfig.options.entrypoints || undefined;
const travelGroupLimits: any = tokenConfig.options.travelGroupLimits || undefined;
const translations: any = tokenConfig.options.translations || undefined;
const maxDuration: any = tokenConfig.options.maxDuration || undefined;
const salesChannel: any = tokenConfig.options.salesChannel || undefined;
const petsConfig: any = tokenConfig.options.petsConfig || undefined;
const extrasConfig: any = tokenConfig.options.extrasConfig || undefined;
const alternativeResults: any = tokenConfig.options.alternativeResults || false;
const accommodationLabels: any = tokenConfig.options.accommodationLabels || undefined;
const useMarkerClusterer: any = tokenConfig.options.useMarkerClusterer || undefined;
const useReductionPass: any = true === tokenConfig.options.useReductionPass;
const disableMaps: any = tokenConfig.options.disableMaps || undefined;
const disableActioncode: any = tokenConfig.options.disableActioncode || undefined;
const minimalMaps: any = tokenConfig.options.minimalMaps || undefined;
const favicon: any = tokenConfig.options.favicon || undefined;
const useTimeTable: any = tokenConfig.options.useTimeTable || undefined;
const arrivalPrinter: any = tokenConfig.options.arrivalPrinter || undefined;

if (favicon) {
  var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = favicon;
  document.getElementsByTagName('head')[0].appendChild(link);
}

Vue.prototype.$app = appService;

new Vue({
  store,
  render: (h: any) => {
    return h(App, {
      props: {
        usePin: usePin,
        theme: theme,
        token: token,
        colorLoader: colorLoader,
        colorZoomBg: colorZoomBg,
        colorZoomFg: colorZoomFg,
        mapsKey: mapsKey,
        entrypoints: entrypoints,
        accommodationGroups: accommodationGroups,
        translations: translations,
        travelGroupLimits: travelGroupLimits,
        maxDuration: maxDuration,
        salesChannel: salesChannel,
        petsConfig: petsConfig,
        extrasConfig: extrasConfig,
        alternativeResults: alternativeResults,
        useMarkerClusterer: useMarkerClusterer,
        useReductionPass: useReductionPass,
        accommodationLabels: accommodationLabels,
        disableMaps: disableMaps,
        minimalMaps: minimalMaps,
        useTimeTable: useTimeTable,
        arrivalPrinter: arrivalPrinter,
        disableActioncode: disableActioncode,
      },
    });
  },
}).$mount(element);
