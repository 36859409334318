import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'camperparkamsterdam';

const currentButtons = [...newConfig.steps[0].buttons];
const newButtons = [];

for (const currentButton of currentButtons) {
    const btn = JSON.parse(JSON.stringify(currentButton));
    btn.code = `${btn.code}-default`;
    btn.options.salesChannel = 20884;
    if (!btn.options.classes) {
        btn.options.classes = [];
    }

    newButtons.push({ ...btn });
}

for (const currentButton of currentButtons) {
    const btn = JSON.parse(JSON.stringify(currentButton));
    switch (btn.code) {
        case 'nl':
            btn.label = 'Start je aanmelding';
            break;
        case 'en':
        case 'gb':
            btn.label = 'Start your registration';
            break;
        case 'de':
            btn.label = 'Starten Sie Ihre Registrierung';
            break;
        case 'fr':
            btn.label = 'Commencez votre inscription';
            break;
        case 'pt':
            btn.label = 'Iniciar la inscripción';
            break;
        case 'it':
            btn.label = 'Iniziate la registrazione';
            break;
        case 'es':
            btn.label = 'Iniciar la inscripción';
            break;
    }

    btn.code = `${btn.code}-bezoek`;
    btn.options.signupVisit = true;
    btn.options.salesChannel = 20508;
    if (!btn.options.classes) {
        btn.options.classes = [];
    }

    if (currentButton.code === currentButtons[0].code) {
        btn.options.classes.push('btn-break-always');
    }

    newButtons.push({ ...btn });
}


newConfig.steps[0].buttons.forEach((button: any) => {
    switch (button.code) {
        case 'nl':
            button.label = 'Boek een kampeerplek';
            break;
        case 'de':
            button.label = 'Buchen Sie einen Stellplatz';
            break;
        case 'en':
        case 'gb':
            button.label = 'Book a camping spot';
            break;
    }
});


newConfig.steps[0] = {
    "type": "default",
    "code": "language",
    "title": "",
    "buttons": newButtons
}

export default newConfig;
