
import Vue from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { wizardProvider } from '@/wizard';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { TommyHelper } from '../TommyHelper';
require('googlemaps-v3-utility-library/markerclusterer/src/markerclusterer');
declare var MarkerClusterer: any;

const DEFAULT_ZOOM: any = 8;
const DEFAULT_LAT: any = 0;
const DEFAULT_LNG: any = 0;

export default Vue.extend({
  props: {
    showAvailable: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      helper: wizardProvider.helper as TommyHelper,
      ready: false,
      minimal: false,
      values: {},
      google: null as any,
      mapBounds: false as any,
      mapConfig: {
        zoom: DEFAULT_ZOOM,
        maxZoom: 14,
        center: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        fullscreenControl: false,
        zoomControl: false,
      } as any,
      mapOverlay: null as any,
      mapBox: null as any,
      map: null as any,
      locations: {} as any,
      markers: [] as any,
      availableMarkers: [] as any,
      chosenLocation: null as any,
      minimalLocation: '' as any,
    };
  },
  async mounted() {
    const meta: any = this.$store.getters.meta;
    let mapsKey: string | boolean = meta.mapsKey || false;
    let mapsEnvKey: string | boolean = process.env.VUE_APP_MAPS_APIKEY || false;

    if (true === meta.minimalMaps) {
      this.minimal = true;

      await this.helper.client.getCalenderAvailability().then((response: any) => {
        Object.keys(response.data.data.locaties).forEach((locationKey: any) => {
          const location: any = response.data.data.locaties[locationKey];

          const isAvailable = true === location.beschikbaar;
          if (isAvailable) {
            Vue.set(this.locations, location.id, location);
          }
        });
      });

      this.ready = true;

      return;
    } else if (this.isFloorplanDisabled || true === meta.disableMaps) {
      this.$store.commit('ADD_SKIPPED_STEP', this.stepNumber);
      this.$emit('choose', { data: false });

      return;
    }

    this.ready = true;

    // fallback to .env maps key in devmode or when no token mapskey is available.
    if ((!mapsKey || ['development', 'test'].includes(process.env.NODE_ENV)) && mapsEnvKey) {
      mapsKey = mapsEnvKey;
    }

    const loader = new Loader({
      libraries: ['geometry'],
      version: "weekly",
      apiKey: mapsKey as string,
      language: 'nl'
      // language: this.locale,
    });

    loader.load().then((google: any) => {
      this.google = google;
      this.initializeMap();
      this.loadLocations();
    }).catch((e: any) => {
      console.error('Failed to load google maps', e);
    });
  },
  methods: {
    getName(acco: any) {
      const locale = this.$store.getters.locale;
      const tommyLocale = this.$store.getters.tommyLocale;
      const translations = acco.vertalingen || [];

      return translations[tommyLocale] || translations[locale] || acco.naam;
    },
    getSorted(data: any) {
      const matchNumeric = /^[0-9]+$/;
      return Object.values(data).sort((a, b) => {
        const nameA = this.getName(a);
        const nameB = this.getName(b);

        if (nameA.match(matchNumeric) && nameB.match(matchNumeric)) {
          return parseInt(nameA) - parseInt(nameB);
        }

        return this.getName(a).localeCompare(this.getName(b))
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector('#map');
      const { Map, LatLngBounds, LatLng, OverlayView } = this.google.maps;

      const USGSOverlay: any = class extends OverlayView {
        bounds_: any;
        image_: any;
        map_: any;
        div_: any;

        constructor(bounds: any, image: any, map: any) {
          super();
          this.bounds_ = bounds;
          this.image_ = image;
          this.map_ = map;

          this.div_ = null;

          this.setMap(map);
          this.set;
        }
        onAdd() {
          const div = document.createElement('div');
          div.style.borderStyle = 'none';
          div.style.borderWidth = '0px';
          div.style.position = 'absolute';

          const img = document.createElement('img');
          img.src = this.image_;
          img.style.width = '100%';
          img.style.height = '100%';
          img.style.position = 'absolute';

          div.appendChild(img);
          this.div_ = div;

          const panes = this.getPanes();
          panes.overlayLayer.appendChild(div);
        }
        draw() {
          const overlayProjection = this.getProjection();
          const sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
          const ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

          const div = this.div_;
          div.style.left = sw.x + 'px';
          div.style.top = ne.y + 'px';
          div.style.width = ne.x - sw.x + 'px';
          div.style.height = sw.y - ne.y + 'px';
        }
        onRemove() {
          this.div_.parentNode.removeChild(this.div_);
          this.div_ = null;
        }
      };

      const srcImage: string = this.apiFloorplan.path + this.apiFloorplan.src;
      const width: number = this.apiFloorplan.width;
      const height: number = this.apiFloorplan.height;
      let mapboxWidth = width;
      let mapboxHeight = height;

      // when not using clustermap, resize drawable div to exactly fit the floorplan
      if (!this.meta.useMarkerClusterer) {
        mapboxWidth = 1030;
        let mapboxRatio = mapboxWidth / width;
        mapboxHeight = height * mapboxRatio;

        // scale until height fits in available sapce.
        while (mapboxHeight >= 1100) {
          mapboxWidth -= 1;
          mapboxHeight = height * mapboxRatio;
          mapboxRatio = mapboxWidth / width;
          mapboxHeight = height * mapboxRatio;
        }

        // center horizontally if not displayed on full width.
        const offsetLeft = (1080 - mapboxWidth) / 2;
        (this.$refs.mapRef as any).style['margin-left'] = `${offsetLeft}px`;
        (this.$refs.mapRef as any).style.width = `${mapboxWidth}px`;
        (this.$refs.mapRef as any).style.height = `${mapboxHeight}px`;
      }

      this.mapBox = this.latLngToBounds(DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, mapboxWidth, mapboxHeight);
      this.mapBounds = new this.google.maps.LatLngBounds(
          new this.google.maps.LatLng(this.mapBox[0][0], this.mapBox[0][1]),
          new this.google.maps.LatLng(this.mapBox[1][0], this.mapBox[1][1]),
      );

      this.map = new Map(mapContainer, this.mapConfig);
      this.mapOverlay = new USGSOverlay(this.mapBounds, srcImage, this.map) as any;
      this.map.setOptions({
        restriction: {
          latLngBounds: {
            north: this.mapBounds.getNorthEast().lat(),
            south: this.mapBounds.getSouthWest().lat(),
            west: this.mapBounds.getSouthWest().lng(),
            east: this.mapBounds.getNorthEast().lng(),
          },
          strictBounds: !this.meta.useMarkerClusterer,
        },
      });

      const map: any = this.map;

      var controlDiv: any = document.createElement('div');
      var controlWrapper = document.createElement('div');

      controlWrapper.style.cursor = 'pointer';
      controlWrapper.style.textAlign = 'center';
      controlWrapper.style.width = '130px';
      controlWrapper.style.height = '55px';
      controlWrapper.style.marginTop = '10px';
      controlWrapper.style.marginRight = '5px';
      controlDiv.appendChild(controlWrapper);

      const meta: any = this.$store.getters.meta;
      const zoomButtonColorBg = meta.colorZoomBg || '#0083A1';
      const zoomButtonColorFg = meta.colorZoomFg || '#FFFFFF';

      var createButton = function(text: string): any {
        var button = document.createElement('div');
        button.style.display = 'inline-block';
        button.style.width = '55px';
        button.style.height = '55px';
        button.style.marginRight = '10px';
        button.style.borderRadius = '10px';
        button.style.background = zoomButtonColorBg;
        button.style.color = zoomButtonColorFg;
        button.style.fontSize = '45px';
        button.style.lineHeight = '55px';
        button.style.fontWeight = 'bold';
        button.style.boxShadow = '1px 1px 4px #666';
        button.style.padding = '0px';

        button.innerHTML = text;

        return button;
      };

      var zoomInButton = createButton('+');
      controlWrapper.appendChild(zoomInButton);

      var zoomOutButton = createButton('-');
      controlWrapper.appendChild(zoomOutButton);

      this.google.maps.event.addDomListener(zoomInButton, 'click', function() {
        map.setZoom(map.getZoom() + 1);
      });

      this.google.maps.event.addDomListener(zoomOutButton, 'click', function() {
        map.setZoom(map.getZoom() - 1);
      });

      controlDiv.index = 1;
      map.controls[this.google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
    },
    loadLocations(): void {
      const markerBounds: any = new this.google.maps.LatLngBounds();
      const infoWindows: any[] = [];

      const markerIconDefault: any = {
        url: '//' + location.host + '/assets/tommy/marker.svg',
        scaledSize: new this.google.maps.Size(40, 52),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(20, 52),
        fillOpacity: 1,
      };

      const markerIconActive: any = Object.assign({}, markerIconDefault);
      markerIconActive.url = '//' + location.host + '/assets/tommy/marker-active.svg';

      const markerIconUnavailable: any = Object.assign({}, markerIconDefault);
      markerIconUnavailable.url = '//' + location.host + '/assets/tommy/marker-unavailable.svg';

      this.helper.client.getCalenderAvailability().then((response: any) => {
        Object.keys(response.data.data.locaties).forEach((locationKey: any) => {
          const location: any = response.data.data.locaties[locationKey];
          this.locations[location.id] = location;

          // hide or choose unavailable marker for unavailable markers.
          const isAvailable = true === location.beschikbaar;
          if (!this.showAvailable && !isAvailable) {
            return;
          }

          const usedMarkerIcon = isAvailable ? markerIconDefault : markerIconUnavailable;
          const latLng: any = this.xyToLatlng(
              parseInt(location.plattegrond.x) / 10000,
              parseInt(location.plattegrond.y) / 10000,
              this.mapBox,
              DEFAULT_ZOOM,
          );

          const markerOptions: any = {
            position: { lat: latLng[0], lng: latLng[1] },
            map: this.map,
            title: location.naam,
            icon: usedMarkerIcon,
          };
          const marker: any = new this.google.maps.Marker(markerOptions);
          markerBounds.extend(marker.getPosition());

          marker.isAvailable = isAvailable;

          if (isAvailable) {
            const infowindow: any = new this.google.maps.InfoWindow({
              content: '' + location.naam,
            });
            infoWindows.push(infowindow);

            marker.addListener('click', () => {
              this.map.setCenter(marker.getPosition());
              this.chosenLocation = location;

              infoWindows.forEach((closeInfo: any) => {
                closeInfo.close();
              });
              this.markers.forEach((closeMarker: any) => {
                closeMarker.setIcon(closeMarker.isAvailable ? markerIconDefault : markerIconUnavailable);
              });

              marker.setIcon(markerIconActive);
              infowindow.open(this.map, marker);
            });
          }

          this.markers.push(marker);
          if (marker.isAvailable) {
            this.availableMarkers.push(marker);
          }
        });

        if (this.mapBounds && !this.meta.useMarkerClusterer) {
          this.map.fitBounds(this.mapBounds);
          this.map.setZoom(1);
        }

        if (process.env.VUE_APP_CYPRESS_RUN) {
          const keys: any = Object.keys(this.locations);
          this.chosenLocation = this.locations[keys[0]];
        }

        if (this.meta.useMarkerClusterer) {
          var mc = new MarkerClusterer(this.map, this.markers, {
            zoomOnClick: true,
            imagePath: process.env.VUE_APP_BASE_URL + '/assets/cluster/m',
            gridSize: 140,
            maxZoom: 11,
          });

          // find bounds of available markers, zoom, fit and re-zoom to stay within boundaries and have markers in the visible viewport.
          if (this.meta.useMarkerClusterer) {
            const availableBounds: any = new this.google.maps.LatLngBounds();
            this.availableMarkers.forEach((tmpMarker: any) => {
              availableBounds.extend(tmpMarker.getPosition());
            });
            this.map.setCenter(availableBounds.getCenter());
            setTimeout(() => {
              this.map.setZoom(1);
            }, 1);
          }

          this.google.maps.event.addListener(mc, 'clusterclick', (cluster: any) => {
            let zoom: any = this.map.getZoom();

            const clusterBounds: any = new this.google.maps.LatLngBounds();
            cluster.getMarkers().forEach((clusterMarker: any) => {
              clusterBounds.extend(clusterMarker.getPosition());
            });

            // must wait for auto zoom by markerClusterer to override the zoom/pan.
            setTimeout(() => {
              // this.map.fitBounds(markerBounds);
              if (cluster && cluster.getCenter()) {
                this.map.setCenter(cluster.getCenter());
                if (this.map.getZoom() > 12) {
                  this.map.setZoom(12);
                } else {
                  this.map.setZoom(zoom + 2);
                }
              }
            }, 10);
          });
        }
      });
    },
    triggerClick: function(): void {
      this.$emit('choose', { data: this.chosenLocation });
    },
    toRadians(degrees: any): any {
      return (degrees * Math.PI) / 180;
    },
    metersPerPixelEW(zoom: number): number {
      const degreesPerMeter: any = 360 / this.earthCirMeters;

      return this.earthCirMeters / Math.pow(2, zoom + 8);
    },
    metersPerPixelNS(zoom: number, lat: any): any {
      return (this.earthCirMeters / Math.pow(2, zoom + 8)) * Math.cos(this.toRadians(lat));
    },
    xyToLatlng(x: any, y: any, box: any, zoom: number): any {
      return [box[1][0] * (1 - y), box[1][1] * x];
    },
    latLngToBounds(lat: any, lng: any, zoom: any, width: any, height: any): any {
      const shiftMetersEW = width * this.metersPerPixelEW(zoom);
      const shiftMetersNS = height * this.metersPerPixelNS(zoom, lat);

      const shiftDegreesEW = shiftMetersEW * this.degreesPerMeter;
      const shiftDegreesNS = shiftMetersNS * this.degreesPerMeter;

      // [[south, west], [north, east]] - north-west = 0,0
      return [
        [lat, lng],
        [lat + shiftDegreesNS, lng + shiftDegreesEW],
      ];
    },
    selectMinimalLocation() {
      if (!this.minimalLocation || typeof this.locations[this.minimalLocation] === 'undefined') {
        this.chosenLocation = null;
      }

      this.chosenLocation = this.locations[this.minimalLocation];
    }
  },
  computed: {
    earthCirMeters(): number {
      return 40075016.686;
    },
    degreesPerMeter(): number {
      return 360 / this.earthCirMeters;
    },
    isFloorplanDisabled(): boolean {
      return this.$store.getters.apiContext.disableFloorplan === true;
    },
    ...mapGetters(['apiFloorplan', 'locale', 'meta', 'stepNumber']),
  },
});
